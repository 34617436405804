import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Typography from 'Components/ui/Typography';
import { tucarBlue } from 'Themes';
import logo from '../../../public/images/logos/isotipo.svg';
import './loading.css';

const LoadingScreen = ({ fullScreen, size }) => {
  const sizePx = (sizeString) => {
    switch (sizeString) {
      case 'big':
        return '300px';
      case 'medium':
        return '100px';
      default:
        return '50px';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: fullScreen ? '100vh' : '100%',
      }}
    >
      <Box
        sx={{
          animationName: 'loading',
          animationDuration: '4s',
          animationIterationCount: 'infinite',
        }}
      >
        <img src={logo} width={sizePx(size)} height={sizePx(size)} alt="logo" />
      </Box>
      <Typography type="p-semiBold" sx={{ color: tucarBlue, fontSize: '1.5rem' }}>
        ...Cargando...
      </Typography>
    </Box>
  );
};

LoadingScreen.propTypes = {
  fullScreen: PropTypes.bool,
  size: PropTypes.string,
};

LoadingScreen.defaultProps = {
  fullScreen: false,
  size: 'medium',
};

export default LoadingScreen;
