import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LabelsContextProvider } from 'Contexts/LabelsContext';
import { getAuthStore, setRefreshRoute } from 'Graphql/store';
import LoadingScreen from 'Components/ui/LoadingScreen';

const AuthGuard = () => {
  const navigate = useNavigate();
  const { accessToken } = getAuthStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setRefreshRoute(window.location.hash.replace('#', ''));
    if (!accessToken) {
      navigate('/', { replace: true });
    } else {
      setLoading(false);
    }
  }, [accessToken, navigate]);

  if (loading) return <LoadingScreen fullScreen size="big" />;

  return (
    <LabelsContextProvider>
      <Outlet />
    </LabelsContextProvider>
  );
};

export default AuthGuard;
