import React from 'react';
import PropTypes from 'prop-types';

import { Typography as MuiTypography } from '@mui/material';

const Typography = ({ children, className, type, ...props }) => {
  const getClass = () => {
    switch (type) {
      case 'g-bold':
        return 'GilroyBold';
      case 'g-mid':
        return 'GilroyMedium';
      case 'p-bold':
        return 'PoppinsBold';
      case 'p-semiBold':
        return 'PoppinsSemiBold';
      case 'p-light':
        return 'PoppinsLight';
      default:
        return className;
    }
  };

  return (
    <MuiTypography className={getClass()} {...props}>
      {children}
    </MuiTypography>
  );
};

Typography.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
};
Typography.defaultProps = {
  children: '',
  className: 'PoppinsRegular',
  type: null,
};

export default Typography;
