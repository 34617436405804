/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';

export const authStore = makeVar({
  accessToken: null,
  userId: null,
  userFirstName: null,
  userLastName: null,
  permissions: {},
});
