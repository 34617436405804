import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ApolloProvider } from '@apollo/client';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as TucarThemeProvider } from '@tucar/pit-stop';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorFallback from 'Components/ErrorFallback';
import client from 'Contexts/graphql';
import AuthContext from 'Contexts/AuthContext';
import { AlertsContextProvider } from 'Contexts/AlertsContext';
import { AlertsContextProviderV2 } from 'Contexts/AlertsContextV2';
import tucar from 'Themes/Tucar';
import Router from './routes';
import 'Themes/fonts.css';

const history = createBrowserHistory();
if (process.env.MODE !== 'development') {
  Sentry.init({
    environment: process.env.MODE,
    dsn: process.env.SENTRY_DNS,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    maxValueLength: 400,
  });
}

const Content = () => (
  <AlertsContextProviderV2>
    <AlertsContextProvider>
      <Box sx={{ backgroundColor: 'primary.backgroundApp' }}>
        <AuthContext>
          <Router />
        </AuthContext>
      </Box>
    </AlertsContextProvider>
  </AlertsContextProviderV2>
);

const App = () => {
  const content =
    process.env.MODE !== 'development' ? (
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <Content />
      </Sentry.ErrorBoundary>
    ) : (
      <Content />
    );
  return (
    <ApolloProvider client={client}>
      <StylesProvider injectFirst>
        <GoogleOAuthProvider clientId={process.env.GOOGLE_AUTH_ID}>
          <HashRouter history={history}>
            <TucarThemeProvider>
              <ThemeProvider theme={tucar}>{content}</ThemeProvider>
            </TucarThemeProvider>
          </HashRouter>
        </GoogleOAuthProvider>
      </StylesProvider>
    </ApolloProvider>
  );
};

export default App;
