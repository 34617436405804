import React, { useContext, createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Box } from '@tucar/pit-stop';

const AlertsContext = createContext();

export const AlertsContextProviderV2 = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const contextValue = useMemo(() => ({
    setSuccessMessage,
    setErrorMessage,
  }));
  return (
    <AlertsContext.Provider value={contextValue}>
      {(successMessage !== '' || errorMessage !== '') && (
        <Box
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999999,
          }}
        >
          {successMessage !== '' && (
            <Alert
              onClose={() => setSuccessMessage('')}
              size="large"
              title="Acción Realizada"
              variant="success"
            >
              {successMessage}
            </Alert>
          )}
          {errorMessage !== '' && (
            <Alert
              onClose={() => setErrorMessage('')}
              size="large"
              title="Ha ocurrido un error"
              variant="error"
            >
              {errorMessage}
            </Alert>
          )}
        </Box>
      )}
      {children}
    </AlertsContext.Provider>
  );
};

AlertsContextProviderV2.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlertsContextV2 = () => useContext(AlertsContext);
