import { styled } from '@mui/system';
import { tucarBlue, realWhite } from 'Themes';

export const StandardButtonStyle = styled('button')`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  background-color: ${realWhite};
  color: ${tucarBlue};
  transition: all 150ms ease;
  cursor: pointer;
  border: 2px solid ${tucarBlue};
  border-radius: 25px;
  line-height: 100%;
  text-transform: none;
  &:hover {
    background-color: ${tucarBlue};
    font-family: PoppinsSemiBold;
    color: ${realWhite};
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: ${tucarBlue};
    font-family: PoppinsRegular;
    opacity: 0.3;
  }
  &:disabled:hover {
    background-color: ${tucarBlue};
    opacity: 0.3;
    color: ${realWhite};
    font-family: PoppinsRegular;
    text-transform: none;
  }
`;

export const StaticStandardButtonStyle = styled('button')`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  background-color: ${realWhite};
  color: ${tucarBlue};
  transition: all 150ms ease;
  cursor: pointer;
  border: 2px solid ${tucarBlue};
  border-radius: 25px;
  line-height: 100%;
  text-transform: none;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: ${tucarBlue};
    font-family: PoppinsRegular;
    opacity: 0.3;
  }
`;
