import { gql } from '@apollo/client';

const GET_LABELS_METADATA = {
  query: gql`
    query GetLabelMetadata($id: ID) {
      getLabelMetadata(_id: $id) {
        _id
        name
        description
        updateType
        classType
        whiteList
        blackList
      }
    }
  `,
  policies: {
    fetchPolicy: 'no-cache',
  },
};

export default GET_LABELS_METADATA;
