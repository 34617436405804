import React, { useContext, createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import SuccessAlert from 'Components/ui/Alert/SuccessAlert';
import ErrorModal from 'Components/ui/ErrorModal';

const AlertsContext = createContext();

export const AlertsContextProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState(null);
  const contextValue = useMemo(() => ({
    setSuccessMessage,
    setErrorMessage,
    setErrorTitle,
  }));
  return (
    <AlertsContext.Provider value={contextValue}>
      <SuccessAlert
        content={successMessage}
        handleClose={() => setSuccessMessage('')}
        open={successMessage !== ''}
      />
      <ErrorModal
        content={errorMessage}
        open={errorMessage !== ''}
        onClose={() => {
          setErrorMessage('');
          setErrorTitle(null);
        }}
        title={errorTitle}
      />
      {children}
    </AlertsContext.Provider>
  );
};

AlertsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlertsContext = () => useContext(AlertsContext);
