import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  getRefreshToken,
  isLoading,
  authStore,
  setLoading,
  setError,
  setSession,
  logout,
  setExpirationDate,
} from 'Graphql/store';
import POST_REFRESH from 'Graphql/querys/auth/refreshToken';
import LoadingScreen from 'Components/ui/LoadingScreen';

const AuthContext = ({ children }) => {
  const [firstTime, setFirstTime] = useState(true);
  const [refreshQuery, { data: queryData, loading, error }] = useLazyQuery(POST_REFRESH.query, {
    ...POST_REFRESH.policies,
  });
  const reactiveAuthStore = useReactiveVar(authStore);
  const authToken = getRefreshToken();

  const refresh = useCallback(async () => {
    setExpirationDate();
    await refreshQuery({ variables: { refreshToken: authToken } });
  }, [authToken, refreshQuery]);

  useEffect(async () => {
    if (authToken && !reactiveAuthStore?.accessToken && firstTime) {
      setFirstTime(false);
      setLoading(true);
      await refresh();
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      const timeout = setTimeout(async () => refresh(), 55 * 60 * 1000);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [reactiveAuthStore]);

  useEffect(() => {
    if (!loading) {
      if (queryData) {
        const { accessToken, refreshToken, data: refreshTokenData } = queryData.refreshToken || {};
        const { firstname: userFirstName, lastname: userLastName, uid: userId } = refreshTokenData;
        setSession({ accessToken, refreshToken, userId, userFirstName, userLastName });
        setLoading(false);
      }
      if (error) {
        setError(true);
        setLoading(false);
        logout();
      }
    }
  }, [loading]);

  return <div>{isLoading() ? <LoadingScreen fullScreen size="big" /> : children}</div>;
};

AuthContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContext;
