import { tucarBlue, tucarGrey, tucarRed, tucarWhite } from './TucarColors';

const tucarPalette = {
  primary: {
    main: tucarBlue,
    dark: tucarBlue,
    grey: tucarGrey,
    error: tucarRed,
    backgroundApp: tucarWhite,
  },
};

export default tucarPalette;
